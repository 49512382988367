import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
// import useWindowSize from '../../../hooks/useWindowSize'
import { ButtonLink } from '../../button'
import { s, colors, alpha } from '../../../style'

const Intro = () => {
  const {
    intro: {
      frontmatter: { slider_intro_index },
    },
  } = useStaticQuery(graphql`
    query {
      intro: markdownRemark(
        fileAbsolutePath: { regex: "/content/index/intro/intro.md/" }
      ) {
        frontmatter {
          slider_intro_index {
            logo {
              publicURL
            }
            image {
              name
              childImageSharp {
                fluid(
                  maxWidth: 1920
                  quality: 100
                  toFormat: JPG
                  jpegProgressive: true
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image_mobile {
              name
              childImageSharp {
                fluid(
                  maxWidth: 500
                  quality: 100
                  toFormat: JPG
                  jpegProgressive: true
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            subtitle
            text
            button {
              text
              link
            }
          }
        }
      }
    }
  `)

  // const size = useWindowSize()

  return (
    <>
      {/* <span css={{ position: 'fixed', top: 0, left: 0, zIndex: 900 }}>
        W: {size.width}, H: {size.height}
      </span> */}
      <div
      // css={
      //   {
      //     // width: '100vw',
      //     // height: '100vh',
      //     // maxWidth: '100vw',
      //     // maxHeight: '100vh',
      //     // margin: '0 auto',
      //     // boxSizing: 'border-box',
      //   }
      // }>
      >
        <CarouselProvider
          visibleSlides={1}
          step={1}
          totalSlides={slider_intro_index.length}
          naturalSlideWidth={1920}
          naturalSlideHeight={1000}
          // lockOnWindowScroll
          isPlaying={false}
          infinite
          interval={5000}
          touchEnabled={false}
          dragEnabled={false}
          css={{
            // backgroundColor: 'red',
            width: '100%',
            height: '100vh',
            minHeight: 600,
            border: 'none',
            outline: 'none',
            '.carousel__slide-focus-ring': { display: 'none' },
            transition: 'height 0.3s ease-out',
          }}>
          <Slider
            style={{ width: '100vw', height: '100vh', minHeight: 600 }}
            css={{
              position: 'relative',
              pointerEvents: 'none',
            }}>
            {slider_intro_index.map(
              (
                { title, subtitle, text, image, image_mobile, logo, button },
                id
              ) => (
                <Slide
                  index={id}
                  key={id}
                  css={{ height: '100vh', minHeight: 600 }}>
                  <div css={{ [s.xs]: { display: 'none' }, height: '100%' }}>
                    <Img
                      fluid={image?.childImageSharp?.fluid}
                      alt={title}
                      style={{ height: '100%' }}
                    />
                  </div>
                  <div css={{ [s.sm]: { display: 'none' }, height: '100%' }}>
                    <Img
                      fluid={image_mobile?.childImageSharp?.fluid}
                      alt={title}
                      style={{ height: '100%' }}
                    />
                  </div>
                  <div
                    css={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '33%',
                      height: '100%',
                      margin: '0 auto',
                      // backgroundColor: "green",

                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#ffffff',
                      padding: '4rem 0rem',
                      [s.xs]: { width: '100%' },
                      [s.sm]: { width: '75%' },
                      [s.md]: {
                        width: '50%',
                        backdropFilter:
                          'blur(24px) brightness(0.9) contrast(1.2)',
                      },
                      [s.lg]: { width: '50%' },
                      [s.xl]: { width: '33%' },
                    }}>
                    <div
                      css={{
                        [s.md]: { display: 'none' },
                        [s.sm_down]: {
                          position: 'absolute',
                          zIndex: 0,
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',

                          background: `linear-gradient(to bottom, ${colors.black.concat(
                            alpha[0]
                          )}, ${colors.black.concat(alpha[60])})`,
                        },
                      }}
                    />
                    {/* <div />
                <div />
                <div /> */}
                    {!logo && (
                      <div
                        css={{
                          padding: '0 2rem',
                          [s.md]: { padding: '0 4rem' },
                        }}>
                        <p
                          css={{
                            filter: `drop-shadow( 1px 2px 12px rgba(0, 0, 0, .3))`,
                            fontFamily: 'Butler',
                            letterSpacing: '-0.05em',
                            lineHeight: '1em',
                            [s.sm_down]: {
                              fontSize: 48,
                              textAlign: 'center',
                              paddingBottom: '1rem',
                            },
                            [s.md]: {
                              fontSize: 96,
                              paddingBottom: '1rem',
                            },
                          }}>
                          {title}
                        </p>
                        <p
                          css={{
                            textTransform: 'uppercase',
                            letterSpacing: '0.25em',
                            fontSize: '1.5rem',
                            [s.sm_down]: {
                              fontSize: 20,
                              textAlign: 'center',
                            },
                            fontWeight: 300,
                          }}>
                          {subtitle}
                        </p>
                      </div>
                    )}
                    {logo && (
                      <img
                        src={logo.publicURL}
                        alt={title}
                        css={{
                          filter: `drop-shadow( 1px 2px 12px rgba(0, 0, 0, .3))`,
                          [s.sm_down]: { width: '60%' },
                          [s.sm]: { width: '50%' },
                          [s.md]: { width: '60%' },
                          [s.lg]: { width: '50%' },
                          [s.xl]: { width: '66%' },
                        }}
                      />
                    )}
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // paddingBottom: '4rem',
                        textAlign: 'center',
                        zIndex: 2,
                      }}>
                      {!title && subtitle && (
                        <p
                          css={{
                            pointerEvents: 'none',
                            textTransform: 'uppercase',
                            letterSpacing: '0.25em',
                            fontSize: '1.5rem',
                            fontWeight: 300,
                          }}>
                          {subtitle}
                        </p>
                      )}
                      <p
                        css={{
                          maxWidth: '66%',
                          padding: '2rem 0 0rem',
                          fontSize: '0.875rem',
                          opacity: 0.6,
                          [s.sm_down]: {
                            opacity: 1,
                            maxWidth: '100%',
                            padding: '4rem 2rem 0rem',
                          },
                          [s.ss]: {
                            padding: '4rem 1rem 0rem',
                          },
                          [s.sm]: {
                            fontSize: '1rem',
                            maxWidth: '100%',
                            opacity: 1,
                          },
                        }}>
                        {text}
                      </p>
                    </div>
                    <ButtonLink
                      to={button?.link}
                      extraCss={{
                        [s.sm]: { display: 'none' },
                        position: 'relative',
                        zIndex: 99,
                        margin: '2rem 0',
                        pointerEvents: 'auto',
                      }}>
                      {button.text}
                    </ButtonLink>
                  </div>
                  <ButtonLink
                    to={button?.link}
                    extraCss={{
                      [s.xs]: { display: 'none' },
                      position: 'absolute',
                      bottom: '6rem',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      pointerEvents: 'auto',
                      '@media screen and (max-height: 680px)': {
                        bottom: '2rem',
                      },
                    }}>
                    {button.text}
                  </ButtonLink>
                </Slide>
              )
            )}
          </Slider>
          <DotGroup css={sDotGroup} />
        </CarouselProvider>
      </div>
    </>
  )
}

export const sDotGroup = {
  [s.sm_down]: {
    bottom: '2rem',
    right: '2rem',
  },
  [s.md]: {
    bottom: '4rem',
    right: '6rem',
  },
  position: 'absolute',
  '.carousel__dot--selected': {
    backgroundColor: 'white',
    opacity: 1,
  },
  button: {
    transition: 'opacity 0.3s ease-out',
    width: '1rem',
    height: '1rem',
    marginLeft: '1rem',
    '&:first-of-type': { marginLeft: 0 },
    outline: 'none',
    backgroundColor: 'white',
    opacity: 0.3,
    '&:hover': {
      opacity: 0.8,
    },
  },
}

export default Intro
